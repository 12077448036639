<template>
    <div class="homepage">
        <PageBuilderInterpreter v-if="content_json" :components-json="content_json" />
        <RecentlyViewedProductsSlider :is-inside-container="false" />
    </div>
</template>

<script>
import { META_ROBOTS_INDEX_FOLLOW } from '@configs/seo';
import { HOMEPAGE_PAGE_IDENTIFIER } from '@configs/homepage';
import { DAYS_FOR_RETURNS } from '@localeConfig/keys';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import CmsPageNotFoundException from '@exceptions/services/cms/CmsPageNotFoundException';

import { ASYNC_SEOMATIC_SERVICE_CONFIG } from '@async-services/seomatic/meta';

import { isRejected } from '@assets/promise';
import { getUrlForSeomatic } from '@assets/seomatic';

import BasePage from '@pages/mixins/BasePage';

import RecentlyViewedProductsSlider from '@organisms/RecentlyViewedProductsSlider/RecentlyViewedProductsSlider';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

export default {
    name: 'Homepage',

    components: {
        RecentlyViewedProductsSlider,
        PageBuilderInterpreter,
    },

    mixins: [BasePage()],

    layout: () => 'default',

    async asyncData({ app, error, store, req, route }) {
        const urlForSeomatic = getUrlForSeomatic(req, route);
        let seomatic = null;

        const [pageBuilderPagePromise, seomaticPromise] = await app.$fetchInParallel([
            app.$services.cms.getPageBuilderPage(HOMEPAGE_PAGE_IDENTIFIER),
            app.$asyncServices.use(
                ASYNC_SEOMATIC_SERVICE_CONFIG.NAME,
                ASYNC_SEOMATIC_SERVICE_CONFIG.METHODS.GET_SEO,
                {
                    url: urlForSeomatic,
                }
            ),
        ]);

        if (isRejected(pageBuilderPagePromise)) {
            const err = pageBuilderPagePromise.reason;

            if (err instanceof CmsPageNotFoundException) {
                return error({
                    statusCode: err.statusCode,
                    message: err.message,
                });
            }

            app.$errorHandler.captureError(
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cms.getPageBuilderPage',
                },
                {
                    HOMEPAGE_PAGE_IDENTIFIER,
                }
            );

            return error({
                statusCode: 500,
                message: 'Error getting homepage cms page.',
            });
        }

        seomatic = seomaticPromise.value;

        const { meta_description, meta_title, content_json } = pageBuilderPagePromise.value || {};

        return {
            seomatic,
            metaLinks: store.getters['config/metaLinks'],
            content_json,
            page: {
                meta: {
                    title: meta_title,
                    description: meta_description,
                },
            },
        };
    },

    head() {
        const { page, seomatic } = this;
        const { title: seoTitle, description: seoDescription, robots: seoRobots } =
            seomatic?.seo?.meta || {};
        const { canonical_url: seoCanonicalUrl } = seomatic?.seo || {};

        const title =
            seoTitle ||
            page.meta.title ||
            [
                // eslint-disable-next-line quotes
                this.$t("Women's, men's and children's fashion"),
                this.$t('MODIVO.PL online store'),
            ].join(' | ');
        const description =
            seoDescription ||
            page.meta.description ||
            [
                // eslint-disable-next-line quotes
                this.$t("Women's, men's and children's fashion"),
                this.$t('Top brands and top manufacturers'),
                this.$t('Free delivery and {days} days return', {
                    days: this.$configProvider.getConfig(DAYS_FOR_RETURNS),
                }),
                this.$t('Stylish shopping at MODIVO.PL'),
            ].join(' | ');

        const link = [
            ...this.metaLinks,
            {
                hid: 'canonical',
                rel: 'canonical',
                href: seoCanonicalUrl || this.canonicalUrl,
            },
        ];

        return {
            titleTemplate: titleChunk => titleChunk,
            title,
            link,
            meta: [
                {
                    hid: 'robots',
                    name: 'robots',
                    content: seoRobots || META_ROBOTS_INDEX_FOLLOW,
                },
                {
                    hid: 'title',
                    name: 'title',
                    content: title,
                },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: title,
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: description,
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: description,
                },
            ],
        };
    },

    beforeCreate() {
        this.$store.dispatch('setRouteRenderStart', {
            name: 'homepage',
            timestampStart: new Date().getTime(),
        });
    },
};
</script>

<style lang="scss" scoped>
.homepage {
    @apply pb-7 min-h-[60vh];

    @screen lg {
        @apply pb-8;
    }
}
</style>
